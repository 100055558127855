
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { AxiosRequestConfig } from "axios";
import { event } from "vue-gtag";

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  employee: {
    firstName: string;
    lastName: string;
  };
  levels: string[];
  subjects: string[];
}

interface NewMeetingData {
  topic: string;
  desc: string;
  salle: string;
  duration: number;
  date: string;
  levels: string[];
  teachers?: Teacher[];
}

export default defineComponent({
  name: "add-meeting-modal",
  components: {},
  emits: ["meeting-edit"],
  props: {
    id: String,
  },

  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const { t } = useI18n();
    const store = useStore();
    const levels = computed(() => store.getters.currentUser.levels);
    const spec = ref("0");
    const teacherList = ref<Teacher[]>([]);

    const targetData = ref<NewMeetingData>({
      topic: "",
      desc: "",
      salle: "",
      duration: 0,
      date: "",
      levels: [],
      teachers: [],
    });

    function getMeetingData(id) {
      ApiService.get("/lms/advisor/meeting/" + id)
        .then(({ data }) => {
          targetData.value = data;
          spec.value = data.teachers && data.teachers.length != 0 ? "1" : "0";
        })
        .catch((e) => {
          console.log(e);
        });
    }

    watch(
      props,
      () => {
        getMeetingData(props.id);
      },
      { deep: true }
    );

    if (props.id) getMeetingData(props.id);

    const rules = ref({
      topic: [
        {
          required: true,
          message: t("course.requiredTopic"),
          trigger: "blur",
        },
      ],
      desc: [
        {
          required: true,
          message: t("course.requiredDesc"),
          trigger: "blur",
        },
      ],
      // date: [
      //   {
      //     required: true,
      //     message: t("course.requiredDate"),
      //     trigger: "blur",
      //   },
      // ],
      duration: [
        {
          // eslint-disable-next-line
          validator: (rule: any, value: number, callback: any) => {
            if (value <= 0) {
              callback(new Error(t("course.durationRule")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          if (
            targetData.value.teachers &&
            targetData.value.teachers.length == 0
          )
            delete targetData.value.teachers;

          ApiService.patch("/lms/advisor/meeting/" + props.id, {
            date: targetData.value.date,
            desc: targetData.value.desc,
            duration: targetData.value.duration,
            salle: targetData.value.salle,
            topic: targetData.value.topic,
          } as AxiosRequestConfig)
            .then(() => {
              event("Edit meeting", {
                event_category: "Meeting",
                event_label: "Meeting section",
                value: 1,
              });
              loading.value = false;
              Swal.fire({
                text: t("meeting.meetingEdited"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("course.okay"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(newTargetModalRef.value);
              });
              emit("meeting-edit");
            })
            .catch(() => {
              Swal.fire({
                text: t("meeting.meetingError"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("course.okay"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              loading.value = false;
            });
        }
      });
    };

    ApiService.post("/schedules/filter", {
      query: {
        status: { $ne: "inactive" },
        hidden: { $ne: true },
      },
      aggregation: [
        {
          $lookup: {
            from: "teachers",
            localField: "teacher",
            foreignField: "_id",
            as: "teacher",
          },
        },
        {
          $lookup: {
            from: "teachers",
            localField: "teacher.employee",
            foreignField: "_id",
            as: "teacherName",
          },
        },
        {
          $lookup: {
            from: "classrooms",
            localField: "classeRoom",
            foreignField: "_id",
            as: "classeRoom",
          },
        },
        {
          $project: {
            _id: 1,
            teacherName: {
              firstName: 1,
              lastName: 1,
            },
            teacher: {
              $let: {
                vars: {
                  teacherVar: {
                    $arrayElemAt: ["$teacher", 0],
                  },
                },
                in: {
                  _id: "$$teacherVar._id",
                  employee: "$$teacherVar.employee",
                },
              },
            },
            classeRoom: {
              $let: {
                vars: {
                  classVar: {
                    $arrayElemAt: ["$classeRoom", 0],
                  },
                },
                in: {
                  name: "$$classVar.name",
                  _id: "$$classVar._id",
                  level: "$$classVar.level",
                },
              },
            },
            subject: 1,
          },
        },
      ],
    })
      .then(({ data }) => {
        data.forEach((data) => {
          const level = String(data.classeRoom.level);
          const t = teacherList.value.find(
            (teacher) => teacher._id == data.teacher._id
          );
          if (levels.value.includes(level)) {
            if (!t) {
              data.teacher.levels = [level];
              teacherList.value.push(data.teacher);
            } else {
              if (!t.levels.includes(level)) t.levels.push(level);
            }
          }
        });
      })
      .catch((e) => console.log(e));

    const clearTeachers = () => {
      targetData.value.teachers = [];
    };

    const avaliableTeachers = computed(() =>
      teacherList.value.filter((t: Teacher) =>
        t.levels.find((l) => targetData.value.levels.includes(l))
      )
    );

    const showDateRequired = ref(false);

    return {
      showDateRequired,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      rules,
      submit,
      t,
      levels,
      spec,
      avaliableTeachers,
      clearTeachers,
    };
  },
});
