
import { defineComponent, onMounted, ref } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import EditMeetingModal from '@/components/calendar/modals/advisor/EditMeeting.vue'
import CreateMeetingModal from '@/components/calendar/modals/advisor/CreateMeeting.vue'

import InfoMeetingModal from '@/components/calendar/modals/advisor/InfoMeeting.vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { Modal } from 'bootstrap'
import moment from 'moment'
import ApiService from '@/core/services/ApiService'
import { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'calendar-advisor-meeting',
  components: {
    FullCalendar,
    CreateMeetingModal,
    EditMeetingModal,
    InfoMeetingModal,
  },
  setup() {
    const calendar = ref()

    const selectedMeeting = ref('')
    const selectedDate = ref('')

    // eslint-disable-next-line
    const meetings = ref<any[]>([])
    // eslint-disable-next-line
    const events = ref<any[]>([])
    const selectedEvent = ref<any>({})
    const calendarLoading = ref(true)

    const { t } = useI18n()

    const meetingInfo = (info) => {
      const record = info.event._def.extendedProps
      selectedMeeting.value = record._id
      if (record.model == 'meeting') {
        const modal = new Modal(
          document.getElementById('kt_modal_meeting_info')
        )
        modal.show()
      } else {
        const modal = new Modal(document.getElementById('kt_modal_event_info'))
        modal.show()
        selectedEvent.value = events.value.find((e) => e._id == record._id)
      }
    }

    const addMeeting = (info) => {
      selectedDate.value = info.date

      const modal = new Modal(document.getElementById('kt_modal_add_meeting'))
      modal.show()
    }

    const onDropEvent = (info) => {
      const meet = info.event
      ApiService.patch('/lms/advisor/meeting/' + meet._def.extendedProps._id, {
        date: meet.start,
      } as AxiosRequestConfig)
        .then()
        .catch((e) => {
          console.log(e)
        })
    }

    const requestMeetings = async () => {
      await ApiService.get('/lms/advisor/meeting')
        .then(({ data }) => {
          meetings.value = data
          calendar.value.getApi().refetchEvents()
        })
        .catch((e) => {
          console.log(e)
        })
      await ApiService.get('/events/teacher')
        .then(({ data }) => {
          events.value = data
          calendar.value.getApi().refetchEvents()
        })
        .catch((e) => {
          console.log(e)
        })
    }

    onMounted(async () => {
      await requestMeetings()
      calendarLoading.value = false
    })

    function getMeetingData(info, successCallback) {
      // eslint-disable-next-line
      const data: any[] = []
      meetings.value.forEach((meet) => {
        data.push({
          _id: meet._id,
          title: meet.topic,
          model: 'meeting',
          start: moment(meet.date).format('YYYY-MM-DD[T]HH:mm:ss'),
          end: moment(
            new Date(meet.date).getTime() + 1000 * 60 * 60 * meet.duration
          ).format('YYYY-MM-DD[T]HH:mm:ss'),
          description: meet.desc,
          className: 'fc-event-danger fc-event-solid-warning',
          color: '#23ced1',
        })
      })
      events.value.forEach((event) => {
        data.push({
          _id: event._id,
          model: 'event',
          title: `${
            event.type != 'other'
              ? '(' + t('calendar.' + event.type) + ') '
              : ''
          } ${event.title}`,
          start: moment(event.start).format('YYYY-MM-DD[T]HH:mm:ss'),
          end: moment(event.end).format('YYYY-MM-DD[T]HH:mm:ss'),
          description: event.desc,
          className: 'fc-event-danger fc-event-solid-warning',
          color: event.color,
        })
      })
      successCallback(data)
    }

    const calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      //  initialDate: moment().startOf("day"),
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,

      views: {
        dayGridMonth: { buttonText: 'month' },
        timeGridWeek: { buttonText: 'week' },
        timeGridDay: { buttonText: 'day' },
      },

      editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      events: getMeetingData,
      dateClick: addMeeting,
      eventDrop: onDropEvent,
      eventClick: meetingInfo,
    }

    function onMeetingCreate() {
      requestMeetings()
    }

    function onDeleteMeeting() {
      Swal.fire({
        title: t('meeting.confirmDelete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('course.acceptDelete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`/lms/advisor/meeting/${selectedMeeting.value}`)
            .then(() => {
              meetings.value = meetings.value.filter(
                (meeting) => meeting._id != selectedMeeting.value
              )
              calendar.value.getApi().refetchEvents()
              Swal.fire({
                text: t('meeting.meetingDeleted'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: t('course.okay'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
            .catch((e) => {
              console.log(e)
              Swal.fire({
                text: t('meeting.meetingError'),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: t('course.okay'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              })
            })
        }
      })
    }

    function onMeetingEdited() {
      requestMeetings()
    }

    function onEditMeeting() {
      const modal = new Modal(document.getElementById('kt_modal_edit_meeting'))
      modal.show()
    }

    return {
      calendarOptions,
      addMeeting,
      calendar,
      onMeetingCreate,
      selectedDate,
      selectedMeeting,
      onDeleteMeeting,
      onEditMeeting,
      onMeetingEdited,
      t,
      selectedEvent,
      calendarLoading,
      moment,
    }
  },
})
