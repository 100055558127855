
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import AdvisorMeetings from "@/components/calendar/advisorMeetings.vue";

export default defineComponent({
  name: "advisor-myMeetings",
  components: {
    AdvisorMeetings,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageTitle(t("meeting.myMeetings"));
    });

    return {
      t,
      moment,
      loading,
    };
  },
});
