
import ApiService from "@/core/services/ApiService";
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import moment from "moment";

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  employee: {
    firstName: string;
    lastName: string;
  };
  levels: string[];
  subjects: string[];
}

interface MeetingData {
  topic: string;
  desc: string;
  salle: string;
  duration: number;
  date: string;
  levels: string[];
  teachers?: Teacher[];
}

export default defineComponent({
  name: "add-meeting-modal",
  components: {},
  emits: ["edit-meeting", "delete-meeting"],
  props: {
    id: String,
  },

  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const pageLoading = ref<boolean>(true);
    const { t } = useI18n();
    const store = useStore();
    const levels = computed(() => store.getters.currentUser.levels);

    const attendance = ref([]);

    async function getMeetingData(id) {
      pageLoading.value = true;
      await ApiService.get("/lms/advisor/meeting/" + id)
        .then(({ data }) => {
          meeting.value = data;
        })
        .catch((e) => {
          console.log(e);
        });

      ApiService.get("/lms/advisor/meeting/attendance/" + id)
        .then(({ data }) => {
          attendance.value = data;
          pageLoading.value = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }

    function editMeeting() {
      emit("edit-meeting");
    }

    function deleteMeeting() {
      emit("delete-meeting");
    }

    watch(
      props,
      () => {
        getMeetingData(props.id);
      },
      { deep: true }
    );

    if (props.id) getMeetingData(props.id);

    const meeting = ref<MeetingData>({
      topic: "",
      desc: "",
      salle: "",
      duration: 0,
      date: "",
      levels: [],
      teachers: [],
    });

    return {
      formRef,
      newTargetModalRef,
      pageLoading,
      meeting,
      t,
      levels,
      moment,
      editMeeting,
      deleteMeeting,
      attendance,
    };
  },
});
